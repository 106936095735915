import Device from '../class/Device';
import gsap from 'gsap';

class Cards {
	constructor() {
		if (Device.isMobile()) {
			this.sliderManager();
		} else {
			this.animManager();
		}
	}

	sliderManager() {
		$('.section-cards .cards__list').slick({
			arrows: false,
			dots: true,
		});
	}

	animManager() {
		if (!Device.isMobile()) {
			gsap.fromTo(
				$('.section-cards .cards__list li'),
				{ y: 30, autoAlpha: 0 },
				{
					scrollTrigger: {
						trigger: '.section-cards .cards__list',
						start: 'top bottom-=10%',
						end: 'top 50%',
						scrub: 0.3,
					},
					y: 0,
					autoAlpha: 1,
					duration: 1,
					stagger: 1,
					ease: 'power4.out',
				}
			);
		}
	}
}

export default Cards;
