import LogoBanner from './flexibles/LogoBanner';
import Scrolling from './flexibles/Scrolling';
import Splash from './flexibles/Splash';
import TypedText from './flexibles/TypedText';
import Contact from './flexibles/Contact';
import ImageContent from './flexibles/ImageContent';
import ImageContentWaves from './flexibles/ImageContentWaves';
import Quotes from './flexibles/Quotes';
import Banner from './flexibles/Banner';
import ContentWithTabs from './flexibles/ContentWithTabs';
import Faq from './flexibles/Faq';
import ScrollingColored from './flexibles/ScrollingColored';
import Cards from './flexibles/Cards';
import Blog from './flexibles/Blog';
import Testimonials from './flexibles/Testimonials';

class Flexibles {
	SECTIONS = {
		'scrolled-content': Scrolling,
		'scrolled-content-colored': ScrollingColored,
		'section-splash': Splash,
		'typed-texts': TypedText,
		'logo-banner': LogoBanner,
		'contact-map': Contact,
		'image-content': ImageContent,
		'image-content-waves': ImageContentWaves,
		'section-banner': Banner,
		'content-with-tabs': ContentWithTabs,
		'section-faq': Faq,
		'section-cards': Cards,
		'section-blog': Blog,
		'testimonials-section': Testimonials,
		'quotes-section': Quotes,
	};

	sectionsArr = [];

	constructor() {
		this.FlexiblesInit();
	}

	FlexiblesInit() {
		for (let className in this.SECTIONS) {
			if (document.querySelector(`.${className}`)) {
				this.sectionsArr.push(new this.SECTIONS[className](className));
			}
		}
	}

	destroy() {
		this.sectionsArr.forEach((elt) => {
			if (typeof elt.destroy === 'function') elt.destroy();
		});
	}
}

export default Flexibles;
