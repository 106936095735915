import Device from '../class/Device';

class ImageContent {
	constructor() {
		this.accordeonManager();
		this.gradientManager();
	}

	accordeonManager() {
		$('.accordeon-group').each((i, elt) => {
			const section = $(elt).parents('section');
			const imgContainer = section.find('.image-content__image-part');
			const firstImage = section.find('.image-content__image-part').css('background-image');

			$(elt)
				.find('.accordeon__header')
				.on('click', function (ev) {
					const imgContainer = $(this).parents('section').find('.image-content__image-part');
					const accordeon = $(this).parents('.accordeon');
					const accordeonContent = accordeon.find('.accordeon__content');
					const accordeonImages = accordeon.find('.ordered-content__logos img').clone();
					// Close others tab
					const activeAccordeon = $(elt).find('.accordeon.active').not(accordeon);
					if (activeAccordeon.length) {
						imgContainer.find('img').remove();
						activeAccordeon.removeClass('active');
						gsap.to(activeAccordeon.find('.accordeon__content'), { height: 0, duration: 0.6, ease: 'power4.out' });
					}

					accordeon.toggleClass('active');

					if (accordeon.hasClass('active')) {
						// Show tab content
						gsap.set(accordeonContent, { height: 'auto' });
						gsap.from(accordeonContent, { height: 0, duration: 0.6, ease: 'power4.out' });
						// Show Image
						imgContainer.append(accordeonImages);
						gsap.from(imgContainer.find('img'), { autoAlpha: 0 });
					} else {
						gsap.to(accordeonContent, { height: 0, duration: 0.6, ease: 'power4.out' });
						// Remove Image
						gsap.to(imgContainer.find('img'), { autoAlpha: 0, onComplete: () => imgContainer.find('img').remove() });
					}
				});
		});
	}

	gradientManager() {
		$('.image-content').each((i, elt) => {
			const gradientBck = $(elt).find('.gradient');
			const gridImage = $(elt).find('.image-part__bck');
			// Mouse cursor animation
			var parentOffset = $(elt).offset();
			let relX = 0,
				relY = 0;
			const colorFrom = $(elt).attr('colorFrom');
			const colorTo = $(elt).attr('colorTo');
			$(elt).on('mousemove', (e) => {
				relX = ((e.pageX - parentOffset.left) / $(elt).width()) * 100;
				relY = ((e.pageY - parentOffset.top) / $(elt).height()) * 100;
				// Grid Anim
				gsap.set(gridImage, { x: relX * -0.075, y: relY * -0.075 });
				// Gradient Anim
				gsap.set(gradientBck, {
					backgroundImage:
						'radial-gradient(circle at top left, ' + colorFrom + ' ' + (relX - 50) + '%, ' + colorTo + ' ' + 100 + '%',
				});
			});
		});
	}
}

export default ImageContent;
