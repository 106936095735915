class Testimonials {
	constructor() {
		this.sliderManager();
	}

	sliderManager() {
		$('.testimonials-section').each((i, elt) => {
			const sliderElt = $(elt).find('.testimonials__list');
			const prevArrow = $(elt).find('.testimonials__controllers .left-arrow');
			const nextArrow = $(elt).find('.testimonials__controllers .right-arrow');

			sliderElt.on('init', () => {
				let maxHeight = 0;
				const slides = sliderElt.find('.slick-slide');
				slides.each((i, elt) => {
					const slideHeight = $(elt).height();
					if (slideHeight > maxHeight) maxHeight = slideHeight;
				});
				slides.height(maxHeight);
			});

			const slider = sliderElt.slick({
				slidesToShow: 3,
				prevArrow: prevArrow,
				nextArrow: nextArrow,
				responsive: [
					{
						breakpoint: 1102, // tablet-header breakpoint
						settings: {
							slidesToShow: 2,
							slidesToScroll: 1,
						},
					},
					{
						breakpoint: 768, // mobile breakpoint
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1,
							arrows: false,
							dots: true,
						},
					},
				],
			});
		});
	}
}

export default Testimonials;
