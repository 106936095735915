import Device from '../class/Device';

class Banner {
	constructor() {
		this.gradientManager();
	}

	gradientManager() {
		$('.section-banner').each((i, elt) => {
			const gradientBck = $(elt).find('.gradient');
			const gridImage = $(elt).find('.banner__img');
			// Mouse cursor animation
			var parentOffset = $(elt).offset();
			let relX = 0,
				relY = 0;
			const colorFrom = $(elt).attr('colorFrom');
			const colorTo = $(elt).attr('colorTo');
			$(elt).on('mousemove', (e) => {
				relX = ((e.pageX - parentOffset.left) / $(elt).width()) * 100;
				relY = ((e.pageY - parentOffset.top) / $(elt).height()) * 100;
				// Grid Anim
				gsap.set(gridImage, { x: relX * -0.075, y: relY * -0.075 });
				// Gradient Anim
				gsap.set(gradientBck, {
					backgroundImage:
						'radial-gradient(circle at bottom left, ' +
						colorFrom +
						' ' +
						(relX - 35) +
						'%, ' +
						colorTo +
						' ' +
						100 +
						'%',
				});
			});
		});
	}
}

export default Banner;
