import gsap from 'gsap';
import { DrawSVGPlugin } from 'gsap/all';
gsap.registerPlugin(DrawSVGPlugin);
class Splash {
	constructor() {
		this.animsManager();
	}

	animsManager() {
		$('.section-splash').each((i, elt) => {
			const svg = $(elt).find('.svg-container');
			const lines = svg.find('path.line');
			const cards = svg.find('.card');
			const logo = svg.find('.logo');
			gsap
				.timeline()
				.delay(0.5)
				.addLabel('start')
				.fromTo(lines, { drawSVG: '0%' }, { drawSVG: '100%', stagger: 0.1, ease: 'linear' }, 'start')
				.from(
					cards,
					{ scale: 0.5, autoAlpha: 0, transformOrigin: 'center', stagger: 0.1, ease: 'back.out(2)' },
					'start+=0.5'
				)
				.from(logo, { y: 10, autoAlpha: 0, stagger: 0.1 }, 'start');
		});
	}
}

export default Splash;
