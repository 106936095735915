class TypedText {
	constructor() {
		this.timer = false;
		gsap.globalTimeline.clear();
		this.animsManager();
	}

	animsManager() {
		$('.typed-texts').each((i, elt) => {
			const currentText = $(elt).find('.text.active');
			this.showText(currentText, $(elt));
		});
	}

	showText(text, container) {
		const characters = text.attr('data-txt').split('');
		text.empty();
		text.show();
		$.each(characters, (i, elt) => {
			if (elt != ' ') {
				text.append('<span>' + elt + '</span>');
			} else {
				// Empty span for spacing
				text.append('<span class="empty"></span>');
			}
		});
		gsap.set(text.find('span'), {
			display: 'inline-block',
			stagger: 0.12,
		});

		this.timer = setTimeout(() => {
			// Clean current text
			text.hide();
			text.find('span').contents().unwrap();
			text.find('.empty').replaceWith(' ');

			// Remove active class
			container.find('.text').removeClass('active');

			// Find next text
			let nextText = text.next();
			if (!nextText.length) nextText = container.find('.text').first();
			nextText.addClass('active');

			// Animate next text
			this.animsManager(nextText);
		}, 5500);
	}

	destroy() {
		clearTimeout(this.timer);
	}
}

export default TypedText;
