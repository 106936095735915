import Device from '../class/Device';
import gsap from 'gsap';

class ImageContentWaves {
	constructor() {
		if (Device.isMobile()) {
			this.sliderManager();
		}
	}

	sliderManager() {
		$('.image-content-waves .image-content-waves__lines').slick({
			arrows: false,
			dots: true,
		});
	}
}

export default ImageContentWaves;
