import Device from '../class/Device';
import gsap from 'gsap';

class Scrolling {
	constructor() {
		//this.StickySlider();
		if (!Device.isMobile()) {
			setTimeout(() => {
				this.stickyManager();
				this.sliderManager();
				this.progressManager();
			}, 500);
		}
	}

	stickyManager() {
		const duration = $('.scrolled-content__container').height() - $(window).height() + $('#site-header').height();
		const startOffset = $('#site-header').height();
		gsap.to('.scrolled-content__container', {
			scrollTrigger: {
				trigger: '.scrolled-content__right',
				pin: true,
				start: 'top +=' + startOffset,
				end: '+=' + duration,
			},
		});
	}

	sliderManager() {
		const stepsOffsets = [];
		$('.scrolled-content__step').each((i, elt) => {
			const duration = $(elt).height();
			gsap.to(elt, {
				scrollTrigger: {
					trigger: elt,
					start: 'top 70%',
					end: '+=' + $(elt).height(),
					onEnter: () => {
						this.showSlide($(elt).index());
					},
					onEnterBack: () => {
						this.showSlide($(elt).index());
					},
				},
			});
		});
	}

	showSlide(index) {
		$('.scrolled-content__image').removeClass('active');
		$('.scrolled-content__image').eq(index).addClass('active');
	}

	progressManager() {
		// Animated progress bar
		const progressBar = $('.scrolled-content .progress .bar');
		gsap.to(progressBar, {
			scrollTrigger: {
				trigger: progressBar,
				start: 'top 50%',
				scrub: 1,
				end: '+=' + $('.scrolled-content .progress').height(),
			},
			height: '100%',
		});
	}
}

export default Scrolling;
