import gsap from 'gsap';
import ScrollMagic from 'scrollmagic';
import InfiniteScroll from 'infinite-scroll';
import { post } from '../utils/functions';

export default class Blog {
	constructor() {
		this.sections = document.querySelectorAll('.section-blog');
		this.sections.forEach((section) => new Section(section));
	}
}

class Section {
	constructor(section) {
		this.section = section;
		this.postsPerPage = section.dataset.postsPerPage;
		this.postsContainer = section.querySelector('.posts__list');
		// this.loadMoreButton = section.querySelector('.load-more');

		this.initInfiniteScroll();
	}

	initInfiniteScroll = () => {
		const path = 'page/{{#}}';
		// this.loadMoreButton.style.display = 'inline-block';

		this.InfiniteScroll = new InfiniteScroll(this.postsContainer, {
			path,
			append: '.post',
			status: '.page-load-status',
			hideNav: '.pagination',
			// button: this.loadMoreButton,
			scrollThreshold: true,
			history: true,
		});

		this.InfiniteScroll.on('append', (body, path, items) => {
			if (items.length < 6) {
				this.InfiniteScroll.destroy();
				// this.loadMoreButton.style.display = 'none';
			}
		});
	};
}
