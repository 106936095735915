import Form from '../class/Form';
import { post } from '../utils/functions';

class Contact {
	constructor(className) {
		this.sections = document.querySelectorAll(`.${className}`);
		this.sections.forEach((section) => {
			new Section(section);
		});
	}
}

class Section {
	constructor(section) {
		this.section = section;
		this.isSubmitting = false;

		this.form = new Form(section.querySelector('form'), {
			onSubmit: this.onSubmit,
		});
		this.tabsManager();
		this.fileManager();
	}

	fileManager() {
		$('input[type=file]').each((i, elt) => {
			const label = $(elt).parents('.input--file').find('.file-label');
			const labelVal = label.find('span').html();

			elt.addEventListener('change', function (e) {
				var fileName = '';
				if (this.files && this.files.length > 1) {
					fileName = (this.getAttribute('data-multiple-caption') || '').replace('{count}', this.files.length);
				} else {
					fileName = e.target.value.split('\\').pop();
				}

				if (fileName) label.find('span').html(fileName);
				else label.find('span').html(labelVal);
			});
		});
	}

	tabsManager() {
		$(this.section)
			.find('.contact__subject-selector')
			.on('click', (ev) => {
				// Retrieve tab Index
				const tabIndex = $(ev.currentTarget).index();

				// Toggle active class on selector
				$(this.section).find('.contact__subject-selector').removeClass('active');
				$(ev.currentTarget).addClass('active');

				// Hide previous tab
				$(this.section).find('.contact__subject-container.active').removeClass('active');

				// Show corresponding tab
				const correspondingTab = $(this.section).find('.contact__subject-container').eq(tabIndex);
				correspondingTab.addClass('active');

				/* Show/Hide specials options in form */
				// Retrieve flags
				const showBook = correspondingTab.attr('data-book');
				const showFile = correspondingTab.attr('data-file');

				// Retrieve input fields
				const inputBook = $(this.section).find('.input--book');
				const inputFile = $(this.section).find('.input--file');

				// Show/hide
				if (showBook > 0) inputBook.css('display', 'block');
				else inputBook.css('display', 'none').find('input').val('');

				if (showFile > 0) inputFile.show();
				else inputFile.hide().find('input').val('');
			});
	}

	onSubmit = () => {
		if (this.isSubmitting) return null;

		this.isSubmitting = true;
		post(
			{
				form: this.form.el,
				action: 'send_contact_mail',
			},
			this.onSubmitResponse,
			true
		);
	};

	onSubmitResponse = (res) => {
		const response = JSON.parse(res);
		if (response.success) {
			this.form.submitButton.success(site.translations.contact.message_sent);
		} else {
			this.form.displayErrors([site.translations.contact.error]);
			this.form.submitButton.reset();
			this.isSubmitting = false;
		}
	};
}

export default Contact;
