import Device from '../class/Device';
import gsap from 'gsap';

class Quotes {
	constructor() {
		// this.sliderManager();
	}

	sliderManager() {
		$('.quotes__list').slick({
			arrows: true,
			dots: false,
			autoplay: true,
			slidesToShow: 1,
			prevArrow: $('.quotes__controllers .left-arrow'),
			nextArrow: $('.quotes__controllers .right-arrow'),
			responsive: [
				{
					breakpoint: 1020, // mobile breakpoint
					settings: {
						arrows: false,
						dots: true,
					},
				},
			],
		});
	}
}

export default Quotes;
