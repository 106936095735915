import Device from '../class/Device';

class ScrollingColored {
	constructor() {
		if (!Device.isMobile()) {
			this.progressManager();
			this.stickyManager();
		} else {
			this.sliderManager();
		}
	}

	sliderManager() {
		$('.scrolled-content-colored__steps .steps-container').slick({
			arrows: false,
			dots: true,
			adaptiveHeight: true,
		});
	}

	progressManager() {
		// Animated progress bar
		const progressBar = $('.scrolled-content-colored .progress .bar');
		gsap.to(progressBar, {
			scrollTrigger: {
				trigger: progressBar,
				start: 'top 75%',
				scrub: 1,
				end: '+=' + $('.scrolled-content-colored .progress').height(),
			},
			height: '100%',
			ease: 'linear',
		});
	}

	stickyManager() {
		const duration = $('.scrolled-content-colored').outerHeight() - $(window).height() + $('#site-header').height();
		const startOffset = $('#site-header').height();
		gsap.to('.scrolled-content-colored', {
			scrollTrigger: {
				trigger: '.scrolled-content-colored .bck-img',
				pin: true,
				start: 'top +=' + startOffset,
				end: '+=' + duration,
			},
		});
	}
}

export default ScrollingColored;
