import Device from '../class/Device';

class Faq {
	constructor() {
		this.accordeonManager();
	}

	accordeonManager() {
		$('.accordeon-group').each((i, elt) => {
			$(elt)
				.find('.accordeon__header')
				.on('click', function (ev) {
					const accordeon = $(this).parents('.accordeon');
					const accordeonContent = accordeon.find('.accordeon__content');
					// Close others tab
					const activeAccordeon = $('.section-faq').find('.accordeon.active').not(accordeon);
					if (activeAccordeon.length) {
						activeAccordeon.removeClass('active');
						gsap.to(activeAccordeon.find('.accordeon__content'), { height: 0, duration: 0.6, ease: 'power4.out' });
					}

					accordeon.toggleClass('active');

					if (accordeon.hasClass('active')) {
						// Show tab content
						gsap.set(accordeonContent, { height: 'auto' });
						gsap.from(accordeonContent, { height: 0, duration: 0.6, ease: 'power4.out' });
					} else {
						gsap.to(accordeonContent, { height: 0, duration: 0.6, ease: 'power4.out' });
					}
				});
		});
	}
}

export default Faq;
