import Device from '../class/Device';

class ContentWithTabs {
	constructor() {
		this.slider = false;

		this.accordeonManager();
		if (Device.isMobile()) {
			this.sliderManager();
		} else {
			this.tabsManager();
		}

		let timeout = false;
		$(window).on('resize', (ev) => {
			clearTimeout(timeout);
			timeout = setTimeout(() => {
				if (Device.isMobile()) {
					this.sliderManager();
				} else {
					this.tabsManager();
				}
			}, 200);
		});
	}

	sliderManager() {
		if (this.slider) {
			slider.slick('unslick');
		}
		this.slider = $('.content-with-tabs .tabs__content').slick({
			arrows: false,
			dots: true,
			adaptiveHeight: true,
		});
	}

	accordeonManager() {
		$('.accordeon-group').each((i, elt) => {
			$(elt)
				.find('.accordeon__header')
				.on('click', function (ev) {
					const accordeon = $(this).parents('.accordeon');
					const accordeonContent = accordeon.find('.accordeon__content');
					// Close others tab
					const activeAccordeon = $(elt).find('.accordeon.active').not(accordeon);
					if (activeAccordeon.length) {
						activeAccordeon.removeClass('active');
						gsap.to(activeAccordeon.find('.accordeon__content'), { height: 0, duration: 0.6, ease: 'power4.out' });
					}

					accordeon.toggleClass('active');

					if (accordeon.hasClass('active')) {
						// Show tab content
						gsap.set(accordeonContent, { height: 'auto' });
						gsap.from(accordeonContent, { height: 0, duration: 0.6, ease: 'power4.out' });
					} else {
						gsap.to(accordeonContent, { height: 0, duration: 0.6, ease: 'power4.out' });
					}
				});
		});
	}

	tabsManager() {
		if (this.slider != false) {
			this.slider.slick('unslick');
			$('.tab__header').first().click();
		}
		$('.tabs-group').each((i, elt) => {
			$(elt)
				.find('.tab__header')
				.on('click', function () {
					// Retrieve tab Index
					const tabIndex = $(this).index();

					// Toggle active class on selector
					$(elt).find('.tab__header').removeClass('active');
					$(this).addClass('active');

					// Hide previous tab
					$(elt).find('.tab__content.active').removeClass('active');

					// Show corresponding tab
					const correspondingTab = $(elt).find('.tab__content').eq(tabIndex);
					correspondingTab.addClass('active');
				});
		});
	}
}

export default ContentWithTabs;
