import { addTransition } from '../utils/functions';
import gsap from 'gsap';

export default class Menu {
	distanceBeforeSticky = window.innerHeight / 10;
	isMenuOpen = false;
	header = document.getElementById('site-header');

	constructor() {
		this.toggleMenu();
		this.stickyMenu();

		// REMOVE NO SCROLL ON PAGE CHANGE
		document.querySelector('body').classList.remove('no-scroll');
		this.header.classList.remove('active');

		const menuItems = document.querySelectorAll('.menu-item, .site-logo, .footer_phone-number, .footer-contact');
		menuItems.forEach((item) => {
			item.addEventListener('click', (ev) => {
				const activeItems = document.querySelectorAll('.current_page_item');
				activeItems.forEach((activeItem) => activeItem.classList.remove('current_page_item'));

				const link = item.querySelector('a');
				const newActivesLinks = document.querySelectorAll(`a[href="${link.href}"]`);
				link.parentElement.classList.add('current_page_item');
				newActivesLinks.forEach((item) => item.parentElement.classList.add('current_page_item'));
			});
		});
		const hashtagLinks = document.querySelectorAll('a[href*="#"]');
		hashtagLinks.forEach((link) => link.parentElement.classList.remove('current_page_item'));
	}

	stickyMenu = () => {
		let header = this.header;
		window.addEventListener('scroll', (ev) => {
			if (window.pageYOffset > this.distanceBeforeSticky && !this.isSticky()) {
				header.classList.add('sticky');
			} else if (window.pageYOffset < this.distanceBeforeSticky && this.isSticky()) {
				header.classList.remove('sticky');
			}
		});
	};

	isSticky = () => this.header.classList.contains('sticky');

	toggleMenu = (action = 'toggle') => {
		const ctaMenu = $('.cta-menu');
		const menuOverlay = $('.menu-overlay');
		const menuItems = menuOverlay.find('.menu-item');

		const menuTl = gsap
			.timeline({ paused: true })
			.addLabel('start')
			.to(menuOverlay, { y: 0, duration: 0.8, ease: 'power4.out' })
			.fromTo(
				menuItems,
				{ y: -30, autoAlpha: 0 },
				{ y: 0, autoAlpha: 1, duration: 0.4, ease: 'power4.out', stagger: 0.1 },
				'start+=0.35'
			);

		ctaMenu.on('click', (ev) => {
			$(ev.currentTarget).toggleClass('is-active');
			this.isMenuOpen = !this.isMenuOpen;

			if (this.isMenuOpen) {
				// Show Menu
				menuTl.play();
			} else {
				menuTl.reverse();
			}
		});
	};
}
