import Device from './class/Device';
import Menu from './class/Menu';
import Flexibles from './Flexibles';
import { CountUp } from 'countup.js';
import { scrollToElement } from './utils/functions';
import gsap from 'gsap';
import { ScrollToPlugin, ScrollTrigger } from 'gsap/all';
import Swup from 'swup';
import ScrollMagicController from './class/ScrollMagicController.js';
gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);
export default class App {
	constructor() {
		this.flexibles = new Flexibles();

		this.revealManager();
		this.anchorManager();
		this.underScoreManager();
		this.countUpManager();
		this.nlConfirmManager();
		this.menu = new Menu();

		var contactCTAs = document.querySelectorAll('.header__nav .cta, .price__cta');
		contactCTAs.forEach((el) =>
			el.addEventListener('click', function () {
				document.querySelector('button.wpb-pcf-form-fire.wpb-pcf-btn-medium.wpb-pcf-btn.wpb-pcf-btn-default').click();
				var titleTemp = el.innerText;
				console.log(titleTemp);
				setTimeout(function () {
					document.querySelector('.swal2-content [name="your-need"]').value = titleTemp;
				}, 1000);
			})
		);

		if (window.location.href.indexOf('#contact') > -1) {
			document.querySelector('button.wpb-pcf-form-fire.wpb-pcf-btn-medium.wpb-pcf-btn.wpb-pcf-btn-default').click();
		}
	}

	nlConfirmManager() {
		if ($('#content').hasClass('showNlPopin')) {
			gsap.to('.nlConfirm-wrapper', { pointerEvents: 'initial', autoAlpha: 1, duration: 0.3, ease: 'power4.out' });
		}

		$('.nlConfirm-wrapper .cta-close, .nlConfirm-wrapper .cta-arrow').on('click', function (ev) {
			gsap.to('.nlConfirm-wrapper', { pointerEvents: 'none', autoAlpha: 0, duration: 0.3, ease: 'power4.out' });
		});
	}

	countUpManager() {
		$('.countUp').each((i, elt) => {
			// Build counter
			const value = parseInt($(elt).html());
			const startVal = value / 2;
			const options = {
				startVal: startVal,
				duration: 2.1,
			};
			const counter = new CountUp(elt, value, options);

			// Start counter when element enter viewport
			ScrollTrigger.create({
				trigger: elt,
				start: 'top 95%',
				onEnter: () => {
					counter.reset();
					counter.start();
				},
				onEnterBack: () => {
					counter.reset();
					counter.start();
				},
			});
		});
	}

	underScoreManager() {
		$('.underscore').each((i, elt) => {
			gsap
				.timeline({ repeat: -1 })
				.to(elt, { autoAlpha: 0, delay: 0.4, duration: 0.2, ease: 'linear' })
				.to(elt, { autoAlpha: 1, duration: 0.2, delay: 0.4, ease: 'linear' });
		});
	}

	revealManager() {
		document.querySelectorAll('[gsap-reveal], [gsap-reveal-list] li').forEach((elt) => {
			if (Device.isMobile() && elt.hasAttribute('gsap-desktop')) {
				// do nothing
			} else {
				gsap.fromTo(
					elt,
					{ y: 40, autoAlpha: 0 },
					{
						scrollTrigger: {
							trigger: elt,
							start: 'top bottom-=15%',
							end: 'top 50%',
							scrub: 1,
						},
						y: 0,
						autoAlpha: 1,
						duration: 1,
						ease: 'power4.out',
					}
				);
			}
		});

		document.querySelectorAll('[gsap-reveal-left], [gsap-reveal-list-left] li, .markup ol li').forEach((elt) => {
			if (Device.isMobile() && (elt.hasAttribute('gsap-desktop') || $(elt).parents('.markup').length > 0)) {
				// do nothing
			} else {
				gsap.fromTo(
					elt,
					{ x: -40, autoAlpha: 0 },
					{
						scrollTrigger: {
							trigger: elt,
							start: 'top bottom-=15%',
							end: 'bottom 50%',
							scrub: 1,
						},
						x: 0,
						autoAlpha: 1,
						duration: 1,
						ease: 'power4.out',
					}
				);
			}
		});

		document.querySelectorAll('[gsap-reveal-right], [gsap-reveal-list-right] li').forEach((elt) => {
			if (Device.isMobile() && elt.hasAttribute('gsap-desktop')) {
				// do nothing
			} else {
				gsap.fromTo(
					elt,
					{ x: 40, autoAlpha: 0 },
					{
						scrollTrigger: {
							trigger: elt,
							start: 'top bottom-=15%',
							end: 'bottom 50%',
							scrub: 1,
						},
						x: 0,
						autoAlpha: 1,
						duration: 1,
						ease: 'power4.out',
					}
				);
			}
		});

		document.querySelectorAll('[gsap-reveal-fade], [gsap-reveal-list-fade] li').forEach((elt) => {
			if (Device.isMobile() && elt.hasAttribute('gsap-desktop')) {
				// do nothing
			} else {
				gsap.fromTo(
					elt,
					{ autoAlpha: 0 },
					{
						scrollTrigger: {
							trigger: elt,
							start: 'top bottom-=15%',
							end: 'bottom 50%',
							scrub: 1,
						},
						autoAlpha: 1,
						duration: 1,
						ease: 'power4.out',
					}
				);
			}
		});
	}
	anchorManager(ev) {
		$('#site-header .menu a[href^="#"]:not(.cta a)').on('click', (ev) => {
			ev.preventDefault();
			ev.stopPropagation();
			const target = $(ev.currentTarget).attr('href');
			const isHome = document.querySelector('.home');
			if (isHome) {
				gsap.to(window, {
					scrollTo: { y: target, offsetY: $('#site-header').height() },
					duration: 2,
					ease: 'power4.inOut',
				});
			} else {
				window.location = siteUrl + target;
			}
		});

		$('.arrow-bottom img').on('click', (ev) => {
			gsap.to(window, { scrollTo: '#ils-nous-font-confiance' });
		});

		$('#ils-nous-font-confiance a.splash__cta.cta').on('click', (ev) => {
			ev.preventDefault();
			ev.stopPropagation();

			gsap.to(window, {
				scrollTo: '.quotes-section',
				duration: 2,
				ease: 'power4.inOut',
			});
		});

		// Clean up URL
		// window.history.replaceState(null, null, window.location.pathname);
		// const isHome = document.querySelector('.home');
		// if (!isHome) {
		// 	console.log('pas la home');
		// 	var menuElements = document.querySelectorAll('.header__nav .menu-item:not(.cta)');
		// 	menuElements.forEach((el) => {
		// 		var hrefValue = el.querySelector('a').href;
		// 		console.log(hrefValue);
		// 		var hostName = window.location.hostname;
		// 		const anchor = hrefValue.split('#');
		// 		console.log(anchor[1]);
		// 		el.querySelector('a').href = '/#' + anchor[1];
		// 	});
		// }
		// const links = document.querySelectorAll('.splash__cta');
		// links.forEach((link) => {
		// 	const anchor = link.href.replace(window.location.href, '');
		// 	if (anchor.indexOf('#') === 0 && anchor.length > 1) {
		// 		const element = document.querySelector(anchor);
		// 		if (element) {
		// 			link.removeAttribute('href');
		// 			link.addEventListener('click', (ev) => {
		// 				ev.preventDefault();
		// 				gsap.to(window, {
		// 					scrollTo: anchor,
		// 					duration: 0.7,
		// 					ease: 'power2.out',
		// 				});
		// 			});
		// 		}
		// 	}
		// });
		// document
		// 	.querySelectorAll('[data-scrollto]:not(.footer-column__nav a)')
		// 	.forEach((el) => el.addEventListener('click', scrollToElement));
	}

	destroy() {
		this.flexibles.destroy();
	}
}

document.addEventListener('DOMContentLoaded', function (ev) {
	let app = new App();
	const swup = new Swup();
	swup.on('contentReplaced', () => {
		app = new App(false);
		window.scrollTo(0, 0);
	});
	swup.on('willReplaceContent', () => {
		app.destroy();
	});
});
