import Device from '../class/Device';
import gsap from 'gsap';
import src from 'gsap/src';

gsap.registerPlugin(ScrollTrigger);

class LogoBanner {
	constructor() {
		this.animsManager();
		this.modalManager();
	}

	animsManager() {
		ScrollTrigger.batch('.logo-banner .logos li', {
			onEnter: (batch) => gsap.to(batch, { y: 0, autoAlpha: 1, duration: 0.4, ease: 'power4.out', stagger: 0.125 }),
			start: 'top 90%',
			end: 'top 70%',
		});
	}

	modalManager() {
		const logoModalsMobile = document.querySelectorAll('.logos-mobile li');
		const logoModals = document.querySelectorAll('.logos li');

		const modalClose = document.querySelector('.logo-banner-modal__close');

		logoModals.forEach((logoModal) =>
			logoModal.addEventListener('click', function (e) {
				e.stopPropagation();

				const logoHref = this.querySelector('.logo-part').getAttribute('data-href');
				const logoSrc = this.querySelector('.logo-part img').getAttribute('src');
				const logoAlt = this.querySelector('.logo-part img').getAttribute('alt');

				document.querySelector('.logo-banner-modal__logo a').setAttribute('href', logoHref);
				document.querySelector('.logo-banner-modal__logo img').setAttribute('src', logoSrc);
				document.querySelector('.logo-banner-modal__logo img').setAttribute('alt', logoAlt);

				document.querySelector('.logo-banner-modal__company .company__content').innerHTML =
					this.getAttribute('data-company');
				document.querySelector('.logo-banner-modal__mission .mission__content').innerHTML =
					this.getAttribute('data-mission');

				document.querySelector('.logo-banner-modal').classList.add('active');
			})
		);

		logoModalsMobile.forEach((logoModal) =>
			logoModal.addEventListener('click', function (e) {
				e.stopPropagation();

				const logoHref = this.querySelector('.logo-part').getAttribute('data-href');
				const logoSrc = this.querySelector('.logo-part img').getAttribute('src');
				const logoAlt = this.querySelector('.logo-part img').getAttribute('alt');

				document.querySelector('.logo-banner-modal__logo a').setAttribute('href', logoHref);
				document.querySelector('.logo-banner-modal__logo img').setAttribute('src', logoSrc);
				document.querySelector('.logo-banner-modal__logo img').setAttribute('alt', logoAlt);

				document.querySelector('.logo-banner-modal__company .company__content').innerHTML =
					this.getAttribute('data-company');
				document.querySelector('.logo-banner-modal__mission .mission__content').innerHTML =
					this.getAttribute('data-mission');

				document.querySelector('.logo-banner-modal').classList.add('active');
			})
		);

		modalClose.addEventListener('click', function () {
			document.querySelector('.logo-banner-modal').classList.remove('active');
		});
	}

	sliderManager() {
		$('.logo-banner').each((i, elt) => {
			const list = $(elt).find('.logos');
			const nbSlides = list.children().length;
			const initSlider = function () {
				if (Device.isMobile()) {
					list.slick({
						autoplay: true,
						autoplaySpeed: 2000,
						slidesToShow: 2,
						variableWidth: false,
						pauseOnHover: false,
						arrows: false,
					});
				} else {
					if (nbSlides < 7) return;
					list.slick({
						autoplay: true,
						autoplaySpeed: 2000,
						slidesToShow: 6,
						variableWidth: false,
						pauseOnHover: false,
						arrows: false,
					});
				}
			};
			initSlider();

			let timer = false;
			$(window).on('resize', function (ev) {
				list.slick('unslick');
				clearTimeout(timer);
				timer = setTimeout(() => {
					initSlider();
				}, 300);
			});
		});
	}
}

export default LogoBanner;
